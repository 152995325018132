import React from 'react';
import { useState, useContext, useEffect } from "react";

import TempIcon from 'remixicon-react/TempHotLineIcon';

import { ThemeContext } from '../../../App.js';
import "../../style.css";

export default function AirTemp(props) {
    const theme = useContext(ThemeContext);

    const icon = {
        position:'absolute',
        top:5,
        left:5,
        width:16,
        height:16,
        color:'rgb(25, 145, 220)'
    }

    const nameStyle = {
        marginLeft:20
    }

    const valueStyle = {
        display:'inline',
        fontSize:32,
        fontWeight:"bold",
        marginTop:5
    }

    const valueWrapper = {
        position:'relative',
        width:'100%',
        textAlign:'center',
        marginTop:10
    }

    const unitStyle = {
        display:'inline',
        fontSize:16,
        marginLeft:5
    }

    function findParam(name) {
        var params;
        console.log("AirTempCard:findParam:",name);

        for (var i in props.site.parameters) {
            if(props.site.parameters[i].type == name) {
                return props.site.parameters[i];
            }
        }
        console.log("AirTempCard:unknown type:",name);
        return null;
    }

    // Setup
    var tStatus = "Normal";
    var reading = props.reading;
    var message = props.site.message;
    var value;

    // console.log("AirTempCard:site:",props.site);
    console.log("AirTempCard:reading:",props.reading);

    if(message != null) {
        if("senix" in message) {
            value = (((message.senix.temp / 10)*1.8)+32).toFixed(2);
        }
        
        props.reading.thresholds.forEach( threshold => {
            if(value > threshold.value) {
                tStatus = threshold.name;
            }
        })

        console.log("StageCard:reading:",reading);
        console.log(tStatus);

    } else {
        console.log("StageCard:No Message");
        value = 0;
        tStatus = "No Message";

        props.reading.thresholds.forEach( threshold => {
            if(value > threshold.value) {
                tStatus = threshold.name;
            }
        })

        console.log("StageCard:reading:",reading);
        console.log(tStatus);
    }

    return (
        <div className="card airTemp">
            <TempIcon id="windIcon" style={icon}></TempIcon>

            <div className="name" style={nameStyle}>{props.reading.name}</div>
            <div className="wrapper">
                <div className="reading">{value}</div>
                <div className="unit">F</div>
            </div>
        </div>
    )
}