import React from 'react';
import { useState, useContext, useEffect } from "react";
import { ThemeContext } from '../../../App.js';

const stage = {
    max:100,
    min:0,
    offset:20,
    thresholds: [
        {name:"Action Needed",color:"rgb(255,255,0)",value:1000},
        {name:"Minor Flooding",color:"rgb(0,64,128)",value:2000},
        {name:"Moderate Flooding",color:"rgb(0,255,255)",value:5000},
        {name:"Major Flooding",color:"rgb(255,0,0)",value:7500}
    ]
}

export default function PrecipSummary(props) {
    const theme = useContext(ThemeContext);
    const [isOnline, setIsOnline] = useState("offline");
    const [status, setStatus] = useState("No Flooding");

    const wrapper = {
        position:'relative',
        width:300,
        height:150,
        backgroundColor:"rgb(255,255,255)",
        borderRadius:4,
        padding:4,
        boxSizing: 'border-box',
        boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.26)"
    }

    const activeStyle = {
        position:'absolute',
        fontSize:12,
        bottom:5,
        right:5
    }

    const barStyle = {
        position:'absolute',
        top:5,
        bottom:5,
        left:5
    }

    const dateStyle = {
        position:"absolute",
        bottom:5,
        left:5,
        width:'100%',
        fontSize:12,
        color:'rgb(128,128,128)'
    }

    const levelStyle = {
        display:'inline',
        fontSize:36,
        fontWeight:"bold",
        marginTop:5
    }

    const levelWrapper = {
        position:'relative',
        width:'100%',
        textAlign:'center',
        marginTop:20
    }

    const nameStyle = {
        fontSize:16,
        fontWeight:"bold",
        textAlign:'center',
        margin:'auto'
    }

    const unitStyle = {
        display:'inline',
        fontSize:16,
        marginLeft:5
    }

    const statusStyle = {
        width:'100%',
        fontSize:21,
        textAlign:'center',
        color:'rgb(128,128,128)'
    }

    function findParam(name) {
        console.log("PrecipSummary:findParam");
        for (var i in props.site.parameters) {
            console.log("params:",props.site.parameters[i]);
            if(props.site.parameters[i].type == name) {
                return props.site.parameters[i];
            }
        }
        console.log("PrecipSummary:unknown type:",name);
        return null;
    }

    // Setup
    var tStatus = "Normal";
    var lastUpdate;
    var level = 0;
    var levelMM = 0;
    var param;

    console.log("PrecipSummary:site:",props.site);
    console.log("PrecipSummary:msg:",props.site.msg);

    {props.site.active ? activeStyle.color = "green" : activeStyle.color="red"}

    if(props.site.msg != null) {
        param = findParam("precipAccum");
        console.log("PrecipSummary:param:",param);

        lastUpdate = new Date(props.site.lastUpdate).toLocaleString();
        level = (props.site.msg.lufft.precip.abs).toFixed(2);
        
        param.thresholds.forEach( threshold => {
            console.log("M:",props.site.msg.lufft.precip.abs);
            if(props.site.msg.lufft.precip.abs > threshold.value) {
                tStatus = threshold.name;
            }
        })

        console.log(props.site.msg);
        console.log("PrecipSummary:levelMM:",levelMM);
        console.log("PrecipSummary:level:",level);
        console.log(tStatus);

    } else {
        const msg = {};
        lastUpdate = new Date(0);
        levelMM = 0;
        level = 0;
        param = {};
    }

    // setStatus(tStatus);

    return (
        <div style={wrapper} id="precipSummary">
            <div style={nameStyle} id="name">{props.site.name}</div>
            <div style={activeStyle} id="active">{props.site.active ? 'Online' : 'Offline'}</div>
            <div style={dateStyle} id="lastUpdate">{lastUpdate}</div>
            <div style={levelWrapper}>
                <div style={levelStyle}>{level}</div>
                <div style={unitStyle}>in.</div>
            </div>
            <div style={statusStyle}>{tStatus}</div>
            {/* <BarChart style={barStyle} param={param} value={levelMM}></BarChart> */}
        </div>
    )
}