import React from 'react';
import { useState, useContext } from "react";
import { ThemeContext } from '../../../App.js';
import "../../style.css";

export default function SignalQuality(props) {
    const theme = useContext(ThemeContext);

    const valueStyle = {
        display:'inline',
        fontSize:32,
        fontWeight:"bold",
        marginTop:5
    }

    const valueWrapper = {
        position:'relative',
        width:'100%',
        textAlign:'center',
        marginTop:10
    }

    const nameStyle = {
        fontSize:16,
        fontWeight:"bold",
        textAlign:'center',
        width:200,
        margin:'auto'
    }

    const unitStyle = {
        display:'inline',
        fontSize:16,
        marginLeft:5
    }

    function findParam(name) {
        var params;
        console.log("signalCard:findParam:",name);

        for (var i in props.site.parameters) {
            if(props.site.parameters[i].type == name) {
                return props.site.parameters[i];
            }
        }
        console.log("signalCard:unknown type:",name);
        return null;
    }

    // Setup
    var tStatus = "Normal";
    var param;
    var message = props.site.message;
    var value;

    console.log("signalCard:site:",props.site);
    console.log("signalCard:reading:",props.reading);

    if(message != null) {
        if("lte" in message) {
            value = (message.lte.quality).toFixed(2);
        }
        
        param.thresholds.forEach( threshold => {
            if(value > threshold.value) {
                tStatus = threshold.name;
            }
        })

        console.log("rssiCard:reading:",props.reading);
        console.log(tStatus);

    } else {
        console.log("signalCard:No reading in the message");
        value = 0;
        tStatus = "No Message";
    }

    return (
        <div className="wrapper" id="signalQualityCard">
            <div style={nameStyle} id="name">{param.name}</div>
            <div style={valueWrapper}>
                <div style={valueStyle}>{value}</div>
                <div style={unitStyle}>%</div>
            </div>
        </div>
    )
}