import React from 'react';
import { useState, useContext, useEffect } from "react";

import CurrentIcon from '../../../images/Current.svg'

import { ThemeContext } from '../../../App.js';

export default function Current(props) {
    const theme = useContext(ThemeContext);

    const icon = {
        position:'absolute',
        top:5,
        left:5,
        width:16,
        height:16,
        color:'rgb(25, 145, 220)'
    }

    const nameStyle = {
        marginLeft:20
    }

    const wrapper = {
        position:'relative',
        width:200,
        height:100,
        backgroundColor:"rgb(255,255,255)",
        borderRadius:4,
        padding:4,
        boxSizing: 'border-box',
        boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.26)"
    }

    const valueStyle = {
        display:'inline',
        fontSize:32,
        fontWeight:"bold",
        marginTop:5
    }

    const valueWrapper = {
        position:'relative',
        width:'100%',
        textAlign:'center',
        marginTop:10
    }

    const unitStyle = {
        display:'inline',
        fontSize:16,
        marginLeft:5
    }

    function findParam(name) {
        var params;
        console.log("CurrentCard:findParam:",name);

        for (var i in props.site.parameters) {
            if(props.site.parameters[i].type == name) {
                return props.site.parameters[i];
            }
        }
        console.log("CurrentCard:unknown type:",name);
        return null;
    }

    // Setup
    var tStatus = "Normal";
    var param;
    var message = props.site.message;
    var value;

    // console.log("CurrentCard:site:",props.site);
    console.log("CurrentCard:reading:",props.reading);

    if(message != null) {
        if("ina219" in message) {
            value = (message.ina219.current).toFixed(2);
        }
        
        props.reading.thresholds.forEach( threshold => {
            if(value > threshold.value) {
                tStatus = threshold.name;
            }
        })

        console.log(tStatus);

    } else {
        console.log("CurrentCard:No Message");
        value = 0;
        tStatus = "No Message";
    }

    return (
        <div className="card current">
            <img src={CurrentIcon} style={icon} alt="Current Icon"/>
            <div className="name" style={nameStyle}>{props.reading.name}</div>
            <div className="wrapper">
                <div className="reading">{value}</div>
                <div className="unit">mA</div>
            </div>
        </div>
    )
}