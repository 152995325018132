import React from "react";
import AirPressure from "./AirPressure";
import AirTemp from "./AirTemp";
import Current from "./Current";
import Humidity from "./Humidity";
import PrecipAccum from "./PrecipInt";
import PrecipInt from "./PrecipAccum";
import PrecipType from "./PrecipType";
import Signal from "./Signal";
// import SignalQuality from "./SignalQuality";
import Stage from "./Stage";
import Voltage from "./Voltage";
import WindDir from "./WindDir";
import WindSpeed from "./WindSpeed";

const Graphs = {
  airPressureAbs:AirPressure,
  airTemp:AirTemp,
  current: Current,
  humidityRel:Humidity,
  precipAccum:PrecipAccum,
  precipIntensity:PrecipInt,
  precipType:PrecipType,
  signal:Signal,
  // signalQuality:SignalQuality,
  stage: Stage,
  voltage: Voltage,
  windDir: WindDir,
  windSpeed: WindSpeed
};

export default (type,reading,site,messages) => {
  if (typeof Graphs[reading.type] !== "undefined") {
    return React.createElement(Graphs[reading.type], {
      reading:reading,
      site:site,
      messages:messages
    });
  }
  return React.createElement(
    () => <div style={{width:300}}>The {reading.type} graph has not been created yet.</div>
  );
};