import React from 'react';
import { useState, useContext } from "react";
import { ThemeContext } from '../../App.js';
import "../style.css";

import Chart from "react-apexcharts";

export default function PrecipAccum(props) {
  const theme = useContext(ThemeContext);
  const state = {   
    series: [
      {
        data: [
          {
            x: 'Code',
            y: [
              new Date('2019-03-02').getTime(),
              new Date('2019-03-04').getTime()
            ]
          },
          {
            x: 'Test',
            y: [
              new Date('2019-03-04').getTime(),
              new Date('2019-03-08').getTime()
            ]
          },
          {
            x: 'Validation',
            y: [
              new Date('2019-03-08').getTime(),
              new Date('2019-03-12').getTime()
            ]
          },
          {
            x: 'Deployment',
            y: [
              new Date('2019-03-12').getTime(),
              new Date('2019-03-18').getTime()
            ]
          }
        ]
      }
    ],
    options: {
      chart: {
        height: 350,
        type: 'rangeBar'
      },
      plotOptions: {
        bar: {
          horizontal: true
        }
      },
      xaxis: {
        type: 'datetime'
      }
    }
  };

  var options = {
    chart: {
      id: "precipTypeChart", 
      redrawOnWindowResize: true, 
      animations:{enabled:true},
      toolbar:{
        show:true,
        tools:{
          download:false
        },
        export:{
          csv:{
            filename:"precipIntensity",
            headerCategory: 'Date',
            headerValue: 'stage',
            dateFormatter(timestamp) {
              return new Date(timestamp).toDateString()
            }
          }
        }
      }
    },
    plotOptions: {
      bar: {
        borderRadius: 4,
        barHeight: '50%',
        horizontal: true,
        distributed: true,
        dataLabels: {
          hideOverflowingLabels: false
        }
      }
    },
    title: {
      text:"", 
      align: 'center',
      style:{fontSize:'21px'}
    },
    markers: {
      hover: {size: 10}
    },
    dataLabels: {
      enabled:false
    },
    colors:['#0080FF'],
    tooltip: {x:{format:"MM-dd HH:mm:ss"}},
    xaxis: {type: 'datetime',labels: {format: 'MM-dd HH:mm',datetimeUTC:false}},
    zoom:{enabled:true,type:"xy",autoScaleYaxis:true},
  }

  {console.log("PrecipTypeGraph:reading:",props.reading)}
  // {console.log("VoltGraph:site:",props.site)}
  // {console.log("VoltGraph:messages:",props.messages)}

  options.title.text = props.reading.name

  // Add precip types
  var series = [];
  var sObj = {};
  
  sObj.data = []
  series.push(sObj);

  props.reading.thresholds.forEach(reading => {
    var dataObj = {};

    dataObj.x = reading.name;
    dataObj.y = [];
    dataObj.fillColor = reading.color;

    props.messages.forEach(message => {
      var dt = message.msg.timestamp * 1000;

      if(message.msg.precip.type === reading.value) {
        dataObj.y.push(dt)
      }
    })

    dataObj.y.push(new Date('2024-08-20').getTime());
    dataObj.y.push(new Date('2024-08-22').getTime());
    series[0].data.push(dataObj);
  })

  console.log("Series:",series);

  return (
    <Chart 
      className="chart"
      type="rangeBar"
      width="100%"
      height="300px"
      options={options}
      series={series}
    >
    </Chart>
  )
}

